/*
import Highway from '@dogstudio/highway';
import fade from './transition'

const H = new Highway.Core({
    transitions:{
        //default: fade
    }
});
*/
function sanJoseTime() {
    const date = new Date();

    const pst = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
    });

    document.getElementById("pstTime").innerHTML = pst;
    console.log(pst);
}

sanJoseTime();